import {useEffect, useState} from "react";
import {useImmer} from "use-immer";
import {customersStore} from "../../../customers-store";
import {userStore} from "../../../../Login/userstore";

const initialUpdate = {
    jwt: '',
    customer: {
        customerId: '',
        customerName: '',
        customerLocation: '',
        customerCOCNumber: '',
        customerURL: ''
    }
}

const CustomersChange = () => {

    //=================== customersStore ===============
    const loadingCustomer = customersStore((state) => state.loadingCustomer);
    const currentCustomer = customersStore((state) => state.customer);
    const fetchCustomer = customersStore((state) => state.fetchCustomer);
    const updated = customersStore((state) => state.customersAreUpdated);
    const setUpdated = customersStore((state) => state.setCustomersAreUpdated)
    const updateCustomer = customersStore((state) => state.updateCustomer)
    const deleteCustomer = customersStore((state) => state.deleteCustomer)
    const initializeUpdate = customersStore((state) => state.initializeUpdate)
    const jwt = userStore((state) => state.jwt);
    //=================== customersStore ===============

    const [urlHasErrors, setUrlHasErrors] = useState(false);
    const [nameHasErrors, setNameHasErrors] = useState(false);
    const [locationHasErrors, setLocationHasErrors] = useState(false);
    const [cocHasErrors, setCocHasErrors] = useState(false);

    const errorMessage = "This is a mandatory field";
    //================= Begin Change handlers=====================

    const urlChangeHandler = (event) => {
        setUpdate(draft => {
            draft.customer.customerURL = event.target.value;
        });
        setUrlHasErrors(false);
    }

    const nameChangeHandler = (event) => {
        setUpdate(draft => {
            draft.customer.customerName = event.target.value;
        });
        setNameHasErrors(false);
    }

    const locationChangeHandler = (event) => {
        setUpdate(draft => {
            draft.customer.customerLocation = event.target.value;
        });
        setLocationHasErrors(false);
    }

    const kvkChangeHandler = (event) => {
        setUpdate(draft => {
            draft.customer.customerCOCNumber = event.target.value;
        });
        setCocHasErrors(false);
    }

    //================= End Change handlers=====================

    //================= Begin Blur handlers=====================
    const urlBlurHandler = () => {
        if (update.customer.customerURL === '') setUrlHasErrors(true);
    }

    const nameBlurHandler = () => {
        if (update.customer.customerName === '') setNameHasErrors(true);
    }

    const locationBlurHandler = () => {
        if (update.customer.customerLocation === '') setLocationHasErrors(true);
    }

    const cocBlurHandler = () => {
        if (update.customer.customerCOCNumber === '') setCocHasErrors(true);
    }
    //================= End Blur handlers=====================

    const [update, setUpdate] = useImmer(initialUpdate);


//     //================= Begin Submission handling=====================
    const afterSubmit = () => {
        setUpdated(!updated);//the list of customers
        initializeUpdate();
        resetForm();
    }

    const resetForm = () => {
        setUrlHasErrors(false);
        setNameHasErrors(false);
        setLocationHasErrors(false);
        setCocHasErrors(false);
    }
    const updateHandler = (event) => {
        event.preventDefault();
        //We send the update to the back-end via the store
        updateCustomer(update).then(afterSubmit);//This call returns a promise, so we can use the .then operator
        resetForm();
    }
//
    const cancelHandler = (event) => {
        event.preventDefault();
        initializeUpdate();
        resetForm();
    }
//
    const deleteHandler = (event) => {
        event.preventDefault();
        deleteCustomer(update).then(afterSubmit);
    }
// //================= End Submission handling=====================

    useEffect(() => {
        setUpdate({
            jwt: jwt,
            customer: {
                customerId: currentCustomer.customerId,
                customerName: currentCustomer.customerName,
                customerLocation: currentCustomer.customerLocation,
                customerCOCNumber: currentCustomer.customerCOCNumber,
                customerURL: currentCustomer.customerURL
            }
        });

    }, [currentCustomer])

    return (
        <form className="customers-change">
            <div className="row">
                <div className="customers-change-label">Customer URL</div>
                <div>
                    <input className="customers-change-field"
                           type="text"
                           value={update.customer.customerURL}
                           onChange={urlChangeHandler}
                           onBlur={urlBlurHandler}/>
                    {!!urlHasErrors && <p className="customers-change-error-message">{errorMessage}</p>}
                </div>
            </div>

            <div className="row">
                <div className="customers-change-label">Customer Name</div>
                <div>
                    <input className="customers-change-field"
                           value={update.customer.customerName}
                           type="text"
                           onChange={nameChangeHandler}
                           onBlur={nameBlurHandler}/>
                    {!!nameHasErrors && <p className="customers-change-error-message">{errorMessage}</p>}
                </div>
            </div>

            <div className="row">
                <div className="customers-change-label">Customer KvK</div>
                <div>
                    <input className="customers-change-field"
                           value={update.customer.customerCOCNumber}
                           type="text"
                           onChange={kvkChangeHandler}
                    onBlur={cocBlurHandler}/>
                    {!!cocHasErrors && <p className="customers-change-error-message">{errorMessage}</p>}
                </div>
            </div>

            <div className="row">
                <div className="customers-change-label">Customer Location</div>
                <div>
                    <input className="customers-change-field"
                           value={update.customer.customerLocation}
                           type="text"
                           onChange={locationChangeHandler}
                           onBlur={locationBlurHandler}/>
                    {!!locationHasErrors && <p className="customers-change-error-message">{errorMessage}</p>}
                </div>
            </div>


            <div className="u-margin-bottom-medium">{" "}</div>
            {/*===================Buttons===========================*/}
            <div className="customers-change-buttons">
                <div className="customers-change-buttons-container">
                    <button className="customers-change-buttons-button" onClick={updateHandler}>Update</button>
                </div>
                <div className="customers-change-buttons-container">
                    <button className="customers-change-buttons-button" onClick={deleteHandler}>Delete</button>
                </div>
                <div className="customers-change-buttons-container">
                    <button className="customers-change-buttons-button" onClick={cancelHandler}>Cancel</button>
                </div>

            </div>
            {/*===================Buttons===========================*/}

        </form>)
}
export default CustomersChange;