import React from "react";
import VacancyChange from "./VacancyChange/VacancyChange";

/*
* Last update date: 16-01-2023 Sander van Hijfte
* This one reacts on the SplitPaneTop
 */

const VacanciesBottom = (props) => {

    return (
        <div {...props} className="vacancies-bottom">
           <VacancyChange/>
        </div>
    )
};

export default VacanciesBottom;