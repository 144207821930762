import TwoHorizontalPanels from "./VacanciesParts/TwoHorizontalPanels/TwoHorizontalPanels";
import VacanciesTop from "./VacanciesParts/VacanciesTop/VacanciesTop";
import Divider from "./VacanciesParts/Divider/Divider";
import VacanciesBottom from "./VacanciesParts/VacanciesBottom/VacanciesBottom";
import VacanciesHeader from "./VacanciesParts/VacanciesHeader/VacanciesHeader";


// Somehow you need all parts listed beneath Vacancies to make Vacancies
// So we keep everything together under vacancies, even if we are going to reuse parts.

const Vacancies = () => {

    return (
        <TwoHorizontalPanels>
            <VacanciesHeader/>
            <VacanciesTop/>
            <Divider className="separator-row"/>
            <VacanciesBottom/>
        </TwoHorizontalPanels>
    );
}

export default Vacancies;