import {useState, useEffect} from "react";
import {useImmer} from "use-immer";
import {userStore} from "../../Login/userstore";
import {vacanciesStore} from "../vacancies-store";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AddVacancyInput from "./AddVacancyInput";
import {customersStore} from "../../Customers/customers-store";


const AddVacancy = () => {
    const errorMessage = 'This field is mandatory';
    //================= VacanciesStore=====================
    const jwt = userStore((state) => state.jwt);
    const createVacancy = vacanciesStore((state) => state.insertVacancy);
    const setUpdated = vacanciesStore((state) => state.setUpdated);//To trigger reloading VacanciesList
    const updated = vacanciesStore((state) => state.listIsUpdated);//And I have to toggle updated to trigger
    //================= VacanciesStore=====================

    //================= CustomersStore=====================
    const fetchCustomers = customersStore((state) => state.fetchCustomers);
    const customers = customersStore((state) => state.customers);
    //================= CustomersStore=====================

    const today = new Date(Date.now());

    const initialInsert = {
        jwt: jwt,
        vacancy: {
            name: '',
            description: '',
            education: 'HBO+',
            end_date: '',
            start_date: '',
            function_name: 'NIET INVULLEN',
            hours: 36,
            city: '',
            option_to_extend: 'yes',
            published: today,
            requirements: '',
            status: 'new',
            customer: '',
            customer_vacancy_id: '',
            final_date_submission: ''
        }
    }

    const [insert, setInsert] = useImmer(initialInsert);
    const [customerHasError, SetcustomerHasError] = useState(null);
    const [customer_vacancy_idHasError, Setcustomer_vacancy_idHasError] = useState(null);
    const [nameHasError, SetnameHasError] = useState(null);
    const [educationHasError, SeteducationHasError] = useState(null);
    const [hoursHasError, SethoursHasError] = useState(null);
    const [cityHasError, SetcityHasError] = useState(null);
    const [option_to_extendHasError, Setoption_to_extendHasError] = useState(null);
    const [start_dateHasError, Setstart_dateHasError] = useState(null);
    const [end_dateHasError, Setend_dateHasError] = useState(null);
    const [final_date_submissionHasError, Setfinal_date_submissionHasError] = useState(null);
    const [descriptionHasError, SetdescriptionHasError] = useState(null);
    const [requirementsHasError, SetrequirementsHasError] = useState(null);


    const [formIsFilled, setFormIsFilled] = useState(false);


// //=====================Begin Change Handlers==========================//
    const customerChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.customer = event.target.value;
            let selectedIndex = event.target.options.selectedIndex;//This is the index of the list
            draft.vacancy.customer_id = event.target.options[selectedIndex].getAttribute('customerId');//Here we get the customerId from the selected item in the list.
        });
        SetcustomerHasError(false);
    }
    const customer_vacancy_idChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.customer_vacancy_id = event.target.value;
        });
        Setcustomer_vacancy_idHasError(false);
    }
    const nameChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.name = event.target.value;
        });
        SetnameHasError(false);
    }

    const educationChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.education = event.target.value;
        });
        SeteducationHasError(false);
    }
    const hoursChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.hours = event.target.value;
        });
        SethoursHasError(false);
    }
    const cityChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.city = event.target.value;
        });
        SetcityHasError(false);
    }
    const option_to_extendChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.option_to_extend = event.target.value;
        });
        Setoption_to_extendHasError(false);
    }

    const start_dateChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.start_date = event.target.value;
        });
        Setstart_dateHasError(false);
    }
    const end_dateChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.end_date = event.target.value;
        });
        Setend_dateHasError(false);
    }
    const final_date_submissionChangeHandler = (event) => {
        setInsert(draft => {
            draft.vacancy.final_date_submission = event.target.value;
        });
        Setfinal_date_submissionHasError(false);
    }
//=========================End ChangeHandlers======================//

//=========================Begin BlurHandlers======================//


    const customer_vacancy_idBlurHandler = () => {
        if (insert.vacancy.customer_vacancy_id === '') Setcustomer_vacancy_idHasError(true);
    }

    const nameBlurHandler = () => {
        if (insert.vacancy.name === '') SetnameHasError(true);
    }

    const educationBlurHandler = () => {
        if (insert.vacancy.education === '') SeteducationHasError(true);
    }

    const hoursBlurHandler = () => {
        if (insert.vacancy.hours === '') SethoursHasError(true);
    }

    const cityBlurHandler = () => {
        if (insert.vacancy.city === '') SetcityHasError(true);
    }

    const option_to_extendBlurHandler = () => {
        if (insert.vacancy.option_to_extend === '') Setoption_to_extendHasError(true);
    }

    const start_dateBlurHandler = () => {
        if (insert.vacancy.start_date === '') Setstart_dateHasError(true);
    }

    const end_dateBlurHandler = () => {
        if (insert.vacancy.end_date === '') Setend_dateHasError(true);
    }

    const final_date_submissionBlurHandler = () => {
        if (insert.vacancy.final_date_submission === '') Setfinal_date_submissionHasError(true);
    }

    const descriptionBlurHandler = () => {
        if (insert.vacancy.description === '') SetdescriptionHasError(true);
    }

    const requirementsBlurHandler = () => {
        if (insert.vacancy.requirements === '') SetrequirementsHasError(true);
    }

//=========================End Blur Handlers======================//

    //=========================Begin Submission handling======================//


    useEffect(() => {
        console.log("useEffect")
        //This part fires and uses the CURRENT state not the former one!!
        checkIfFormIsFilled();
//        TODO: check why you would fetch all the customers with every input action here?
//        fetchCustomers().then();
    }, [insert]);

    const resetForm = () => {
        setUpdated(!updated);//the list of vacancies
        setInsert(initialInsert); //clears the form
    }

    const handleSubmit = (event) => {
        event.preventDefault();//always to prevent a rerender
        setFormIsFilled(false);//puts the submit button inactive
        const result = createVacancy(insert).then(resetForm);
        console.log(result);
    }

    const handleCancel = (event) => {
        event.preventDefault();
        resetForm();
    }

    const checkIfFormIsFilled = () => {//All fields must have a value
        if (
            insert.vacancy.customer.length > 0 &&
            insert.vacancy.customer_vacancy_id !== '' &&
            insert.vacancy.name !== '' &&
            insert.vacancy.education !== '' &&
            insert.vacancy.hours !== '' &&
            insert.vacancy.city !== '' &&
            insert.vacancy.option_to_extend !== '' &&
            insert.vacancy.start_date !== '' &&
            insert.vacancy.end_date !== '' &&
            insert.vacancy.final_date_submission !== '' &&
            insert.vacancy.description !== '' &&
            insert.vacancy.requirements !== ''
        ) {
            setFormIsFilled(true);
        } else (setFormIsFilled(false));
    }

    //=========================End Submission handling======================//

    const customerList = customers.map(customer => //The blue customerId is a custom attribute I created and it holds the customerId from the customers in the database
        <option key={customer.customerId} customerId={customer.customerId} value={customer.customerName}>{customer.customerName}</option>
    );

    return (
        <form className="add-vacancy">{/*I rather have the submission via a click here*/}

            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Customer</div>
                </div>
                <div className="add-vacancy-customer-box">
                    <select onChange={customerChangeHandler} id="customer" className="add-vacancy-customer">
                        <option value="">Select a customer</option>
                        {customerList}
                    </select>
                </div>
            </div>

            <AddVacancyInput
                label="Customer VacancyId"
                type="text"
                id="customer-vacancy-id"
                value={insert.vacancy.customer_vacancy_id}
                changeHandler={customer_vacancy_idChangeHandler}
                blurHandler={customer_vacancy_idBlurHandler}
                error={customer_vacancy_idHasError}
                errorMessage={errorMessage}
            />

            <AddVacancyInput
                label="Vacancy Name"
                type="text"
                id="customer-vacancy-name"
                value={insert.vacancy.name}
                changeHandler={nameChangeHandler}
                blurHandler={nameBlurHandler}
                error={nameHasError}
                errorMessage={errorMessage}
            />

            <AddVacancyInput
                label="Education"
                type="text"
                id="education"
                value={insert.vacancy.education}
                changeHandler={educationChangeHandler}
                blurHandler={educationBlurHandler}
                error={educationHasError}
                errorMessage={errorMessage}
            />

            <AddVacancyInput
                label="Hours"
                type="text"
                id="hours"
                value={insert.vacancy.hours}
                changeHandler={hoursChangeHandler}
                blurHandler={hoursBlurHandler}
                error={hoursHasError}
                errorMessage={errorMessage}
            />

            <AddVacancyInput
                label="City"
                type="text"
                id="city"
                value={insert.vacancy.city}
                changeHandler={cityChangeHandler}
                blurHandler={cityBlurHandler}
                error={cityHasError}
                errorMessage={errorMessage}
            />

            <AddVacancyInput
                label="Option to Extend"
                type="text"
                id="option_to_extend"
                value={insert.vacancy.option_to_extend}
                changeHandler={option_to_extendChangeHandler}
                blurHandler={option_to_extendBlurHandler}
                error={option_to_extendHasError}
                errorMessage={errorMessage}
            />

            <AddVacancyInput
                label="Start Date"
                type="date"
                id="start_date"
                value={insert.vacancy.start_date}
                changeHandler={start_dateChangeHandler}
                blurHandler={start_dateBlurHandler}
                error={start_dateHasError}
                errorMessage={errorMessage}
            />

            <AddVacancyInput
                label="End Date"
                type="date"
                id="end_date"
                value={insert.vacancy.end_date}
                changeHandler={end_dateChangeHandler}
                blurHandler={end_dateBlurHandler}
                error={end_dateHasError}
                errorMessage={errorMessage}
            />

            <AddVacancyInput
                label="Final Date Submission"
                type="date"
                id="final_date_submission"
                value={insert.vacancy.final_date_submission}
                changeHandler={final_date_submissionChangeHandler}
                blurHandler={final_date_submissionBlurHandler}
                error={final_date_submissionHasError}
                errorMessage={errorMessage}
            />


            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">
                        Description
                    </div>
                </div>
                <div className="column">
                    <CKEditor
                        editor={ClassicEditor}
                        data={insert.vacancy.description}
                        onChange={(event, editor) => {
                            setInsert(draft => {
                                draft.vacancy.description = editor.getData();
                            });
                            SetdescriptionHasError(false);
                        }}
                        id='description'
                        onBlur={descriptionBlurHandler}
                    />
                    {!!descriptionHasError && <p className="error-message">{errorMessage}</p>}
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">
                        Requirements
                    </div>
                </div>
                <div className="column">
                    <CKEditor
                        editor={ClassicEditor}
                        data={insert.vacancy.requirements}
                        onChange={(event, editor) => {
                            setInsert(draft => {
                                draft.vacancy.requirements = editor.getData();
                            });
                            SetrequirementsHasError(false);
                        }}
                        id='description'
                        onBlur={requirementsBlurHandler}
                    />
                    {!!requirementsHasError && <p className="error-message">{errorMessage}</p>}
                </div>
            </div>

            {/*Buttons*/}
            <div className="row-2-columns row-2-columns__two-buttons u-margin-bottom-petit">
                <div className="column">
                    <button disabled={!formIsFilled} className="btn" onClick={handleSubmit}>Submit</button>
                </div>
                <div className="column">
                    <button className="btn" onClick={handleCancel}>Cancel</button>
                </div>
            </div>
            {/*Buttons*/}
        </form>


    );
}
export default AddVacancy;