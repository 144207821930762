import {create} from 'zustand';
import { Buffer } from 'buffer';

const {REACT_APP_BASE_URL_AUTH} = process.env
const {REACT_APP_BASE_URL_USER} = process.env;

const loginURL = REACT_APP_BASE_URL_AUTH + '/login';
const resetPasswordURL = REACT_APP_BASE_URL_USER +  'user/update_pw'

const userStore = create((set) => ({
    user: '',
    setUser: (input) => set((state) => ({user: input})),
    jwt: '',
    setJWT: (input) => set((state) => ({jwt: input})),
    passWord: '',
    setPassWord: (input) => set((state) => ({passWord: input})),
    loggedIn: false,
    setLoggedIn: (input) => set((state) => ({loggedIn: input})),
    login: async (input) => {//The input = {email,passWord}
        let errorMsg = {};
        try {
//            const base64encodedData = Buffer.from(`${input.email}:${input.passWord}`).toString('base64');
//            console.log(base64encodedData)
            const response = await fetch(loginURL, {
                method: 'POST',

//                body: JSON.stringify(input),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${btoa(`${input.email}:${input.passWord}`)}`
//                    'Authorization': `Basic ${btoa('renee@n-tgr.nl:titatest123#')}`
                }
            });
            if (!response.ok) {
                console.log('Not loggedIn');
                errorMsg = await response.json();//The await here is because we are WAITING for a promise
                throw new Error(errorMsg);//The response needs to be passed to the errorMsg
            } else {
                const data = await response;
                const bearer = data.headers.get('authorization');
//                const data = await response.json()
//                console.log(data)
//                const bearer = data;
                if (bearer) {//Only if we can login there will be a bearer
                    set(() => ({jwt: bearer}));
                    set(() => ({loggedIn: true}));
                    return;
                }
                alert('Wrong userid and or password');
            }
        } catch (e) {
            console.log('time: ' + errorMsg.timestamp);
            alert('Wrong userid and or password');
        }
    },
    submitNewPassword: async (input) => {//The input = {email,passWord, newPassword}
        let errorMsg = {};
        try {
            const response = await fetch(resetPasswordURL, {
                method: 'PUT',
                body: JSON.stringify(input),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                console.log('Not loggedIn');
                errorMsg = await response.json();//The await here is because we are WAITING for a promise
                throw new Error(errorMsg);//The response needs to be passed to the errorMsg
            } else {
                const data = await response;
                const bearer = data.headers.get('authorization');
                set(() => ({jwt: bearer}));
                set(() => ({loggedIn: true}));
            }
        } catch (e) {
            console.log('time: ' + errorMsg.timestamp);
            alert('Wrong userid and or password');
        }
    }

}));

export {userStore};