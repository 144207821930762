const AddVacancyInput = (props) => {
    const error = props.error;
    return (
        <div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">{props.label}</div>
                </div>
                <div className="column">
                    <input className="add-vacancy__input"
                           type={props.type}
                           id={props.id}
                           value={props.value}
                           onChange={props.changeHandler}
                           onBlur={props.blurHandler}
                    />
                    {!!error && <p className="error-message">{props.errorMessage}</p>}
                </div>
            </div>
        </div>

    )
}
export default AddVacancyInput;