import logo from '../../assets/logo_n-TGR.jpg'
import Navigation from './Navigation/Navigation'

const Header = () => {
    return(
        <div className="header u-margin-bottom-small">
            <div className="logo-box">
                <img src={logo} alt="logo" className="logo-box__content"/>
            </div>
            <Navigation/>
        </div>
    )
}
export default Header;