import './App.css';

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Routing from "./components/Routing/Routing"
import Login from "./components/Login/Login";
import {userStore} from "./components/Login/userstore";

function App() {
    const loggedIn = userStore((state) => state.loggedIn);

    if (loggedIn) return (
        <div className="App">
            <Header/>
            <Routing/>
            <Footer/>
        </div>
    );
    if (!loggedIn) return (
        <Login/>
    );
}

export default App;
