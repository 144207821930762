import React, {useEffect, useState} from 'react';
import LoginInput from "./LoginInput/LoginInput";

import {userStore} from './userstore';

const Login = () => {
    //==========from the userStore==============//
    const setUser = userStore((state) => state.setUser)
    const login = userStore((state) => state.login);
    const resetPassword = userStore((state) => state.submitNewPassword);
    //==========from the userStore==============//

    //==========Begin local state ==============//
    const [loginIsValid, setLoginIsValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [input, setInput] = useState({
        email: '',
        passWord: '',
        newPassword: ''
    });

    const [loginForm, setLoginForm] = useState(true);
    //==========End local state ==============//

    //==========Begin User Name==============//
    const [userNameTouched, setUserNameTouched] = useState(false)
    const enteredUserNameIsValid = input.email.trim() !== '';
    const userNameIsInvalid = !enteredUserNameIsValid && userNameTouched;

    const userNameChangeHandler = event => {
        setInput({...input, email: event.target.value});
    }
    const userNameBlurHandler = () => {
        setUserNameTouched(true);
    }
    //==========End User Name==============//

    //==========Begin Password==============//
    const [passWordTouched, setPassWordTouched] = useState(false);
    const enteredPassWordIsValid = input.passWord.length > 7;
    const passWordIsInvalid = !enteredPassWordIsValid && passWordTouched;

    const passwordVisibleHandler = (event) => {
        event.preventDefault();
        setShowPassword(!showPassword);
    }

    const passWordChangeHandler = event => {
        setInput({...input, passWord: event.target.value});
    }
    const passwordBlurHandler = () => {
        setPassWordTouched(true);
    }
    //==========End Password==============//

    //==========Begin New Password==============//
    const [newPassWordTouched, setNewPassWordTouched] = useState(false);
    const enteredNewPassWordIsValid = input.newPassword.length > 7;
    const newPassWordIsInvalid = !enteredNewPassWordIsValid && newPassWordTouched;

    const newPassWordChangeHandler = event => {
        setInput({...input, newPassword: event.target.value});
    }
    const newPasswordBlurHandler = () => {
        setNewPassWordTouched(true);
    }
    //==========End New Password==============//

    //==========Begin Confirm Password==============//
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [confirmPassWordTouched, setConfirmPassWordTouched] = useState(false);
    const enteredConfirmPassWordIsValid = confirmNewPassword.length > 7;
    const confirmPassWordIsInvalid = !enteredConfirmPassWordIsValid && confirmPassWordTouched;

    const confirmPassWordChangeHandler = event => {
        setConfirmNewPassword(event.target.value);
    }
    const confirmPasswordBlurHandler = () => {
        setConfirmPassWordTouched(true);
    }
    //==========End Confirm Password==============//

    //==========Begin Reset Password Form Logic==============//
    const [resetIsValid, setResetIsValid] = useState(false);

    const resetReset = () => {
        setInput({...input, newPassword: ''});
        setNewPassWordTouched(false);
        setConfirmNewPassword('');
        setConfirmPassWordTouched(false);
        setLoginForm(true);
    }

    const handleCancelReset = (event) => {//This is for the cancellation of the reset.
        event.preventDefault();
        resetReset();
    }

    const handleResetPassWord = (event) => {
        event.preventDefault();
        setUser(input.email);
        const _newPassword = input.newPassword;
        if (_newPassword === confirmNewPassword) {
            resetPassword(input).then(resetReset);
        } else {
            alert('The New Password must be the same as the Confirm New Password');
        }
    }

    //==========End Reset Password Form Logic==============//

    //==========Begin Form Logic==============//

    useEffect(() => {
        if (enteredUserNameIsValid && enteredPassWordIsValid) {
            setLoginIsValid(true)
            if (enteredNewPassWordIsValid && enteredConfirmPassWordIsValid) {
                setResetIsValid(true)
            } else {
                setResetIsValid(false)
            }
        } else {
            setLoginIsValid(false)
        }


    }, [enteredUserNameIsValid, enteredPassWordIsValid, enteredNewPassWordIsValid, enteredConfirmPassWordIsValid]);

    const loginSubmitHandler = async (event) => {
        event.preventDefault();
        setUser(input.email);
        const loginInput = {
            email: '',
            passWord: ''
        }
        loginInput.email = input.email;
        loginInput.passWord = input.passWord;
        login(loginInput);
    }

    const handleGoToPwdReset = (event) => {//This is to go to the reset form
        event.preventDefault();
        setLoginForm(false);
    }
    //==========End Form Logic==============//

    return (
        <div className="login__form">
            {loginForm && //It is the login form
                <form>
                    <LoginInput
                        label="User Name"
                        placeHolder="User Name*"
                        type="email"
                        id="user-name"
                        changeHandler={userNameChangeHandler}
                        blurHandler={userNameBlurHandler}
                        value={input.email}
                        isInValid={userNameIsInvalid}
                        errorMessage="User Name is mandatory"
                        isPassword={false}
                    />
                    <LoginInput
                        label="Password"
                        placeHolder="Password*"
                        type="password"
                        id="password"
                        changeHandler={passWordChangeHandler}
                        blurHandler={passwordBlurHandler}
                        value={input.passWord}
                        isInValid={passWordIsInvalid}
                        errorMessage="Password must be at least 8 character long"
                        isPassword={true}
                        clickOnShowHide={passwordVisibleHandler}
                        show={showPassword}
                    />
                    {/*Submit button*/}
                    <div className="login__row u-margin-top-small">
                        <button disabled={!loginIsValid} className="login__btn login__column"
                                onClick={handleGoToPwdReset}>Reset Password
                        </button>
                        <button disabled={!loginIsValid} className="login__btn login__column"
                                onClick={loginSubmitHandler}>Login
                        </button>
                    </div>
                    {/*Submit button*/}
                </form>}
            {!loginForm && //It is the reset password form
                <form>
                    <LoginInput
                        label="New Password"
                        placeHolder="New Password*"
                        type="password"
                        id="new-password"
                        changeHandler={newPassWordChangeHandler}
                        blurHandler={newPasswordBlurHandler}
                        value={input.newPassWord}
                        isInValid={newPassWordIsInvalid}
                        errorMessage="Password must be at least 8 character long"
                        isPassword={true}
                        clickOnShowHide={passwordVisibleHandler}
                        show={showPassword}
                    />

                    <LoginInput
                        label="Confirm New Password"
                        placeHolder="Confirm New Password*"
                        type="password"
                        id="confirm-password"
                        changeHandler={confirmPassWordChangeHandler}
                        blurHandler={confirmPasswordBlurHandler}
                        value={confirmNewPassword}
                        isInValid={confirmPassWordIsInvalid}
                        errorMessage="Password must be at least 8 character long"
                        isPassword={true}
                        clickOnShowHide={passwordVisibleHandler}
                        show={showPassword}
                    />
                    <div className="login__row u-margin-top-small">
                        <button
                            disabled={!resetIsValid}
                            className="login__btn"
                            onClick={handleResetPassWord}>Reset Password
                        </button>
                        <button
                            className="login__btn"
                            onClick={handleCancelReset}>Cancel
                        </button>
                    </div>
                </form>
            }
        </div>
    )
}

export default Login;