import {vacanciesStore} from "../../../vacancies-store";
import {useEffect} from "react";
const VacanciesList = () => {
    //===========vacanciesStore ================
    const loadingVacancies = vacanciesStore((state) => state.loadingVacancies);
    const vacancies = vacanciesStore((state) => state.vacancies);
    const fetchVacancies = vacanciesStore((state) => state.fetchVacancies);
    const fetchVacancy = vacanciesStore((state)=>state.fetchVacancy);
    const updated = vacanciesStore((state)=>state.listIsUpdated);
    //=========== vacanciesStore ================

    useEffect(() => {
        fetchVacancies();

    }, [updated]);

    const selectVacancy = (event) => {
        console.log("Selected Vacancy: " + event.target.id);
        fetchVacancy(event.target.id);
    }
    const jobs = vacancies.map(job =>
      <div key={job.vacancyId}
           className="vacancies-list vacancies-list__grid vacancies-list__row u-margin-bottom-petit" onClick={selectVacancy}>
          <div className="vacancies-list__column vacancies-list__field" id={job.vacancyId}>
              {job.vacancyId}
          </div>
          <div className="vacancies-list__column vacancies-list__field" id={job.vacancyId} >
              {job.customer_vacancy_id}
          </div>
          <div className="vacancies-list__column vacancies-list__field" id={job.vacancyId}>
              {job.customer}
          </div>
          <div className="vacancies-list__column vacancies-list__field" id={job.vacancyId}>
              {job.final_date_submission}
          </div>
          <div className="vacancies-list__column vacancies-list__field"id={job.vacancyId}>
              {job.name}
          </div>
      </div>
    );

    if (loadingVacancies) return (<div>Loading</div>)
    if (!loadingVacancies) return (
        <div>
            {/*<VacanciesHeader/>*/}
            {jobs}
        </div>
    );
}
export default VacanciesList;