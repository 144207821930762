const VacanciesHeader = () => {
    return(
<div className="split-pane-top">
    <div className="vacancies">
            <div className="vacancies-list__grid vacancies__header u-margin-bottom-petit">
                <div className="column vacancies__header-label">Vacancy Id</div>
                <div className="column vacancies__header-label">Customer Vacancy Id</div>
                <div className="column vacancies__header-label">Customer</div>
                <div className="column vacancies__header-label">Final Date Submission</div>
                <div className="column vacancies__header-label">Vacancy Name</div>
            </div></div>
</div>
    )
}
export default VacanciesHeader;