import {useState, useEffect} from "react";
import {useImmer} from "use-immer";
import {userStore} from "../../Login/userstore";
import {customersStore} from "../customers-store";
import {vacanciesStore} from "../../Vacancies/vacancies-store";


const AddCustomer = () => {


    const errorMessage = 'This field is mandatory';

    //================= CustomerStore=====================
    const jwt = userStore((state) => state.jwt);
    const createCustomer = customersStore((state) => state.createCustomer);

    //I have noticed that only one will freeze the application.
    const setUpdated = customersStore((state) => state.setCustomersAreUpdated)
    const updated = customersStore((state) => state.customersAreUpdated);
    //================= VacanciesStore=====================

    const initialInsert = {
        jwt: jwt,
        customer: {
            customerId: '',
            customerName: '',
            customerLocation: '',
            customerCOCNumber: '',
            customerURL: ''
        }
    }


    const [insert, setInsert] = useImmer(initialInsert);
    const [urlHasError, setUrlHasError] = useState(null);
    const [nameHasError, setNameHasError] = useState(null);
    const [locationHasError, setLocationHasError] = useState(null);
    const [cocHasError, setCocHasError] = useState(null);
    const [formIsFilled, setFormIsFilled] = useState(false);
    //=========================Begin ChangeHandlers======================//
    const urlChangeHandler = (event) => {
        setInsert(draft => {
            draft.customer.customerURL = event.target.value;
        });
        setUrlHasError(false);
    }

    const nameChangeHandler = (event) => {
        setInsert(draft => {
            draft.customer.customerName = event.target.value;
        });
        setNameHasError(false);
    }

    const locationChangeHandler = (event) => {
        setInsert(draft => {
            draft.customer.customerLocation = event.target.value;
        });
        setLocationHasError(false)
    }

    const cocChangeHandler = (event) => {
        setInsert(draft => {
            draft.customer.customerCOCNumber = event.target.value;
        });
        setCocHasError(false);
    }
    //=========================End ChangeHandlers======================//

    //=========================Begin BlurHandlers======================//

    const urlBlurHandler = () => {
        if (insert.customer.customerURL === '') setUrlHasError(true);
    }

    const nameBlurHandler = () => {
        if (insert.customer.customerName === '') setNameHasError(true);
    }

    const locationBlurHandler = () => {
        if (insert.customer.customerLocation === '') setLocationHasError(true);
    }

    const cocBlurHandler = () => {
        if (insert.customer.customerCOCNumber === '') setCocHasError(true);
    }
    //=========================End BlurHandlers======================//

    //Submission handlers

    const handleCancel = (event) => {
        event.preventDefault();
        resetForm();
    }

    useEffect(() => {
        //This part fires and uses the CURRENT state not the former one!!
        checkIfFormIsFilled();
    }, [insert]);

    const handleSubmit = (event) => {
        event.preventDefault();//always to prevent a rerender
        setFormIsFilled(false);//puts the submit button inactive
        const result = createCustomer(insert).then(resetForm);
    }


    const checkIfFormIsFilled = () => {
        if (
            insert.customer.customerURL !== '' &&
            insert.customer.customerName !== '' &&
            insert.customer.customerLocation !== '' &&
            insert.customer.customerCOCNumber !== ''
        ) setFormIsFilled(true);
        else (setFormIsFilled(false));
    }

    const resetForm = () => {
        setUpdated(!updated);//the list of vacancies
        setInsert(initialInsert); //clears the form
        setUrlHasError(false);
        setNameHasError(false);
        setLocationHasError(false);
        setCocHasError(false);
    }

    return (
        <form className="add-customer">

            <div className="row">
                <div className="add-customer-label">Customer URL</div>
                <div>
                    <input className="add-customer-field"
                           type="text"
                           value={insert.customer.customerURL}
                           onChange={urlChangeHandler}
                           onBlur={urlBlurHandler}
                    />
                    {!!urlHasError && <p className="error-message">{errorMessage}</p>}
                </div>
            </div>

            <div className="row">
                <div className="add-customer-label">Customer Name</div>
                <div>
                    <input className="add-customer-field"
                           type="text"
                           value={insert.customer.customerName}
                           onChange={nameChangeHandler}
                           onBlur={nameBlurHandler}
                    />
                    {!!nameHasError && <p className="error-message">{errorMessage}</p>}
                </div>
            </div>

            <div className="row">
                <div className="add-customer-label">Customer KvK</div>
                <div>
                    <input className="add-customer-field"
                           type="text"
                           value={insert.customer.customerCOCNumber}
                           onChange={cocChangeHandler}
                           onBlur={cocBlurHandler}
                    />
                    {!!cocHasError && <p className="error-message">{errorMessage}</p>}
                </div>
            </div>

            <div className="row">
                <div className="add-customer-label">Customer Location</div>
                <div>
                    <input className="add-customer-field"
                           type="text"
                           value={insert.customer.customerLocation}
                           onChange={locationChangeHandler}
                           onBlur={locationBlurHandler}
                    />
                    {!!locationHasError && <p className="error-message">{errorMessage}</p>}
                </div>
            </div>

            <div className="u-margin-bottom-medium">{" "}</div>


            {/*===================Buttons===========================*/}
            <div className="add-customer-buttons">
                <button disabled={!formIsFilled} className="add-customer-buttons-button" onClick={handleSubmit} >Submit</button>
                <button className="add-customer-buttons-button" onClick={handleCancel}>Cancel</button>
            </div>
            {/*===================Buttons===========================*/}

        </form>
    )
}
export default AddCustomer;