import Customers from "../components/Customers/Customers";

const CustomersPage = () => {
    return(

            <Customers/>

    )
}

export default Customers;