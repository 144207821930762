import {createRef, useContext, useEffect} from "react";
import CustomersPaneContext from "../CustomersPaneContext";
import CustomersList from "./CustomersList/CustomersList";

const CustomersTop = (props) => {
    const topRef = createRef();
    const {clientHeight, setClientHeight} = useContext(CustomersPaneContext);

    useEffect(() => {
        if (!clientHeight) {
            setClientHeight(topRef.current.clientHeight);
            return;
        }

        topRef.current.style.minHeight = clientHeight + "px";
        topRef.current.style.maxHeight = clientHeight + "px";
    }, [clientHeight]);

    return (
        <div {...props} className="customers-top       " ref={topRef}>
            <CustomersList/>
        </div>
    )
};

export default CustomersTop;