const CustomersHeader = () =>{
    return(
        <div className="customers-header">
            <div className="customers-label">Customer URL</div>
            <div className="customers-label">Customer Name</div>
            <div className="customers-label">Customer KvK</div>
            <div className="customers-label">Customer Location</div>
        </div>
    )
}
export default CustomersHeader;