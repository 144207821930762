import {useContext} from "react";
import CustomersPaneContext from "./CustomersPaneContext";

const CustomersDivider = (props) => {
    const {onMouseHoldDown} = useContext(CustomersPaneContext);

    return <div {...props} onMouseDown={onMouseHoldDown}/>

};

export default CustomersDivider;