import {Route, Routes} from 'react-router-dom';
import VacanciesPage from '../../pages/VacanciesPage';
import AddVacancyPage from "../../pages/AddVacancyPage";
import HomePage from '../../pages/HomePage'
import CustomersPage from "../../pages/CustomersPage";
import AddCustomerPage from "../../pages/AddCustomer";

const Routing = () => {

    return (
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/vacancies/" element={<VacanciesPage/>}/>
            <Route path="/add-vacancy/" element={<AddVacancyPage/>}/>{/*When this one is called we can insert*/}
            <Route path="/customers/" element={<CustomersPage/>}/>
            <Route path="/add-customer/" element={<AddCustomerPage/>}/>
            <Route path="*" element={<HomePage/>}/>
        </Routes>
    );
}

export default Routing;
