import CustomersBottom from "./CustomersPanels/CustomersBottom/CustomersBottom";
import CustomersPanels from "./CustomersPanels/CustomersPanels";
import CustomersTop from "./CustomersPanels/CustomersTop/CustomersTop";
import CustomersDivider from "./CustomersPanels/CustomersDivider";
import CustomersHeader from "./CustomersHeader/CustomersHeader";
const Customers = () => {

    return (
        <CustomersPanels>
            <CustomersHeader/>
            <CustomersTop/>
            <CustomersDivider className="separator-row"/>
            <CustomersBottom/>
        </CustomersPanels>
    )
}
export default Customers;