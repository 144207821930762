import {createRef, useContext, useEffect,} from "react";
import PanelContext from "../PanelContext";
import VacanciesList from "./VacanciesList/VacanciesList";

const VacanciesTop = (props) => {
    const topRef = createRef();
    const {clientHeight, setClientHeight} = useContext(PanelContext);


    useEffect(() => {
        if (!clientHeight) {
            setClientHeight(topRef.current.clientHeight);
            return;
        }

        topRef.current.style.minHeight = clientHeight + "px";
        topRef.current.style.maxHeight = clientHeight + "px";
    }, [clientHeight]);

    return (
        <div {...props}  className="vacancies-top" ref={topRef}>
            <VacanciesList />
        </div>
    )
};

export default VacanciesTop;