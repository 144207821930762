import {Link} from 'react-router-dom';
import {userStore} from "../../Login/userstore";

const Navigation = () => {
    const setUser = userStore((state) => state.setUser);
    const setJWT = userStore((state) => state.setJWT);
    const setLoggedIn = userStore((state) => state.setLoggedIn);

    function logOutHandler() {
        setLoggedIn(false);
        setJWT('');
        setUser('');
    }

    return (
        <div className="nav-bar u-margin-bottom-small">
            <Link to="/" className="nav-bar__link">Home</Link>
            <Link to="/vacancies/" className="nav-bar__link">Vacancies</Link>
            <Link to="/add-vacancy/" className="nav-bar__link"> Add Vacancy</Link>
            <Link to="/customers/" className="nav-bar__link"> Customers</Link>
            <Link to="/add-customer/" className="nav-bar__link"> Add Customer</Link>
            <Link to="/login" onClick={logOutHandler} className="nav-bar__link">Log out</Link>
        </div>
    )
}
export default Navigation;