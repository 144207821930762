import {create} from 'zustand';
const {REACT_APP_BASE_URL_VACANCIES} = process.env;

const baseURL = REACT_APP_BASE_URL_VACANCIES;//This is the url of the container on the local machine


const initialVacancy = {
    vacancyId: '',
    name: '',
    description: '',
    education: '',
    end_date: '',
    start_date: '',
    function_name: '',
    hours: '',//Don't think any different
    city: '',
    option_to_extend: '',
    published: '',
    requirements: '',
    status: '',
    identifier: null,
    customer: '',
    customer_id: '',
    customer_vacancy_id: '',
    final_date_submission: ''
};

const vacanciesStore = create((set) => ({
    // ===============Vacancies================
    listIsUpdated: false,
    setUpdated: (input) => set((state) => ({listIsUpdated: input})),
    vacancies: [initialVacancy],
    loadingVacancies: false,
    vacanciesHasErrors: false,
    fetchVacancies: async () => {
        set(() => ({loadingVacancies: true}));
        try {
            const response = await fetch(baseURL + "get_all_active", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );
            const result = await response.json();
            // console.log(result);
            set((state) => ({data: (state.vacancies = result.content), loadingVacancies: false}));
        } catch (err) {
            set(() => ({vacanciesHasErrors: true, loadingVacancies: false}));
        }
    },
    // ===============Vacancies================

    // ===============Vacancy================
    loadingVacancy: false,
    vacancy: initialVacancy,
    initializeUpdate: () => set((state) => ({vacancy: initialVacancy})),
    vacancyHasErrors: false,
    fetchVacancy: async (input) => {
        set(() => ({loadingVacancy: true}));
        try {
            const response = await fetch(baseURL + 'get_by_vacancy_id/' + input, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );
            const result = await response.json();
            // console.log(result);
            set((state) => ({data: (state.vacancy = result), loadingVacancy: false}));
        } catch (err) {
            set(() => ({vacancyHasErrors: true, loadingVacancy: false}));
        }
    },
    updateVacancy: async (input) => {
        let errorMsg = {};
        // console.log(input.jwt);
        // console.log(input.vacancy);
        try {
            let vacancyToBeUpdated = {
                vacancyId: input.vacancy.vacancyId,
                name: input.vacancy.name.replace(/'/g, '\\\''),
                description: input.vacancy.description.replace(/'/g, '\\\''),
                education: input.vacancy.education.replace(/'/g, '\\\''),
                end_date: input.vacancy.end_date,
                start_date: input.vacancy.start_date,
                function_name: input.vacancy.function_name.replace(/'/g, '\\\''),
                hours: input.vacancy.hours,
                city: input.vacancy.city.replace(/'/g, '\\\''),
                option_to_extend: input.vacancy.option_to_extend,
                published: input.vacancy.published,
                requirements: input.vacancy.requirements.replace(/'/g, '\\\''),
                status: input.vacancy.status,
                identifier: input.vacancy.identifier,
                customer: input.vacancy.customer.replace(/'/g, '\\\''),
                customer_id: input.vacancy.customer_id.replace(/'/g, '\\\''),
                customer_vacancy_id: input.vacancy.customer_vacancy_id.replace(/'/g, '\\\''),
                final_date_submission: input.vacancy.final_date_submission
            };
            //The uri must be base/update-vacancy/id
            const response = await fetch(baseURL + input.vacancy.vacancyId, {
                method: 'PATCH',
                body: JSON.stringify(vacancyToBeUpdated),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': input.jwt
                }
            });
            if (!response.ok) {
                errorMsg = await response.json();
                throw new Error(errorMsg);
            }
        } catch (e) {
            alert('The vacancy was not updated');
        }
    },
    insertVacancy: async (input) => {
        let errorMsg = {};
        console.log(input.jwt);
        console.log(input.vacancy);
        let vacancyToBeCreated = {
            name: input.vacancy.name.replace(/'/g, '\\\''),
            description: input.vacancy.description.replace(/'/g, '\\\''),
            education: input.vacancy.education.replace(/'/g, '\\\''),
            end_date: input.vacancy.end_date,//dates don't need the replace
            start_date: input.vacancy.start_date,
            function_name: input.vacancy.function_name.replace(/'/g, '\\\''),
            hours: input.vacancy.hours,
            city: input.vacancy.city.replace(/'/g, '\\\''),
            option_to_extend: input.vacancy.option_to_extend,
            published: input.vacancy.published,
            requirements: input.vacancy.requirements.replace(/'/g, '\\\''),
            status: input.vacancy.status,
            customer: input.vacancy.customer.replace(/'/g, '\\\''),
            customer_id: input.vacancy.customer_id,
            customer_vacancy_id: input.vacancy.customer_vacancy_id.replace(/'/g, '\\\''),
            final_date_submission: input.vacancy.final_date_submission
        };
        try {
            //The uri must be base/update-vacancy/id
            const response = await fetch(baseURL + '', {
                method: 'POST',
                body: JSON.stringify(vacancyToBeCreated),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': input.jwt
                }
            });
            if (!response.ok) {
                errorMsg = await response.json();
                throw new Error(errorMsg);
            }
        } catch (e) {
            alert('The vacancy was not inserted');
        }
    },
    deleteVacancy: async (input) => {
        let errorMsg = {};
        try {
            //The uri must be base/delete-vacancy/id
            const response = await fetch(baseURL + input.vacancyId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': input.jwt
                }
            });
            if (!response.ok) {
                errorMsg = await response.json();
                throw new Error(errorMsg);
            }
        } catch (e) {
            alert('The vacancy was not deleted');
        }
    },
    // ===============Vacancy================

}));

export {vacanciesStore};