import {create} from 'zustand';

const {REACT_APP_BASE_URL_CUSTOMERS} = process.env;

const baseURL = REACT_APP_BASE_URL_CUSTOMERS;

const initialCustomer = {
    customerId: '',
    customerName: '',
    customerCOCNumber: '',
    customerURL: '',
    customerLocation: ''
};

const customersStore = create((set) => ({
    // ===============Customers================
    customersAreUpdated: false,
    setCustomersAreUpdated: (input) => set(state => ({customersAreUpdated: input})),
    customers: [initialCustomer],
    loadingCustomers: false,
    customersHasError: false,
    fetchCustomers: async () => {
        set(() => ({loadingCustomers: true}));
        try {
            const response = await fetch(baseURL + "get_all", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            const result = await response.json();
            console.log(result)
            // console.log(result);
            set((state) => ({data: (state.customers = result.content), loadingCustomers: false}));
        } catch (error) {
            set(() => ({customersHasErrors: true, loadingCustomers: false}));
        }
    },
    // ===============Customers================

    // ===============Customer================
    loadingCustomer : false,
    customer: initialCustomer,
    initializeUpdate: () => set((state) => ({customer: initialCustomer})),
    customerHasError: false,
    fetchCustomer: async (input) => {
        set(()=>({loadingCustomer:true}));
        console.log(input);
        console.log(baseURL);
        const url = baseURL + "get_by_customerid/" + input;
        console.log(url);
        try{
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const result = await response.json();
            set((state) => ({data: (state.customer = result), loadingCustomer: false}));
        }
        catch(error){
            console.log(error.message);
            set(()=>({customerHasError:true, loadingCustomer:false}));
        }
    },
    updateCustomer: async (input) => {
        let errorMsg = {};
        // console.log(input.jwt);
        // console.log(input.customer);
        try {
            let customerToBeUpdated = {
                customerId: input.customer.customerId,
                customerName: input.customer.customerName.replace(/'/g, '\\\''),
                customerURL: input.customer.customerURL,
                customerCOCNumber: input.customer.customerCOCNumber,
                customerLocation: input.customer.customerLocation,
            };
            //The uri must be base/?method=update&id=customerId
            const response = await fetch(baseURL + input.customer.customerId, {
                method: 'PATCH',
                body: JSON.stringify(customerToBeUpdated),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': input.jwt
                }
            });
            if (!response.ok) {
                errorMsg = await response.json();
                alert('The customer was not deleted');
            }
        } catch (e) {
            alert('The customer was not updated');
        }
    },
    deleteCustomer: async (input) => {
        let errorMsg = {};
        try {
            let customerToBeDeleted = {
                customerId: input.customer.customerId,
                customerName: input.customer.customerName.replace(/'/g, '\\\''),
                customerURL: input.customer.customerURL,
                customerCOCNumber: input.customer.customerCOCNumber,
                customerLocation: input.customer.customerLocation,
            };
            const response = await fetch(baseURL + input.customer.customerId, {
                method: 'DELETE',
//                body: JSON.stringify(customerToBeDeleted),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': input.jwt
                }
            });
            if (!response.ok) {
                errorMsg = await response.json();
                throw new Error(errorMsg);
            }
        } catch (e) {
            alert('The customer was not deleted');
        }
    },
    createCustomer: async (input)=>{
        let errorMsg = {};
        try{
            let customerToBeInserted = {
                customerName: input.customer.customerName.replace(/'/g, '\\\''),
                customerURL: input.customer.customerURL,
                customerCOCNumber: input.customer.customerCOCNumber,
                customerLocation: input.customer.customerLocation,
            };
            const response = await fetch(baseURL + "", {
                method: 'POST',
                body: JSON.stringify(customerToBeInserted),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': input.jwt
                }
            });
            if (!response.ok) {
                errorMsg = await response.json();
                throw new Error(errorMsg);
            }
        }catch(e){
            alert('The customer was not added')
        }
    }
}));

export {customersStore};