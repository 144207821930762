import showImage from "../../../assets/hide.jpeg";
import hideImage from "../../../assets/show.jpeg";

const LoginInput = (props) => {
    const isInvalid = props.isInValid;
    const isPassword = props.isPassword;
    const show = props.show;
    let inputType = '';

    if(!isPassword){
        inputType = props.type;
    }else {
        if(show){
            inputType = "text";
        }else{
            inputType = "password";
        }
    }
    return (

        <div className="login__row">
            <div className="login__column">
                <div className="label">{props.label}</div>
            </div>
            <div className="login__column">
                <input
                    type={inputType}
                    placeholder={props.placeHolder}
                    id={props.id}
                    onChange={props.changeHandler}
                    onBlur={props.blurHandler}
                    value={props.value}
                />
                {isInvalid && <div>{props.errorMessage}</div>}
            </div>
            <div>
                {isPassword &&
                    <div>
                        {show && <img src={showImage} onClick={props.clickOnShowHide} alt="password visible" className="login__pwd-image"/>}
                        {!show && <img src={hideImage} onClick={props.clickOnShowHide}  alt="password visible" className="login__pwd-image"/>}
                    </div>
                }
            </div>
        </div>

    )
}
export default LoginInput;