import React, {
    useEffect,
    useRef,
    useState
} from "react";

import PanelContext from "../PanelContext";

const TwoHorizontalPanels = ({children, ...props}) => {
    const [clientHeight, setClientHeight] = useState(300);//This determines the start of the divider
    const [clientWidth, setClientWidth] = useState(null);
    const xDividerPos = useRef(null);
    const yDividerPos = useRef(null);

    const onMouseHoldDown = (e) => {
        yDividerPos.current = e.clientY;
        xDividerPos.current = e.clientX;
    }

    const onMouseHoldUp = () => {
        yDividerPos.current = null;
        xDividerPos.current = null;
    }

    const onMouseHoldMove = (e) => {
        if (!yDividerPos.current && !xDividerPos.current) {
            return;
        }

        setClientHeight(clientHeight + e.clientY - yDividerPos.current);
        setClientWidth(clientWidth + e.clientX - xDividerPos.current);

        yDividerPos.current = e.clientY;
        xDividerPos.current = e.clientX;
    }

    useEffect(() => {
            document.addEventListener("mouseup", onMouseHoldUp);
            document.addEventListener("mousemove", onMouseHoldMove);
            return () => {
                document.removeEventListener("mouseup", onMouseHoldUp);
                document.removeEventListener("mousemove", onMouseHoldMove);
            }
        }
    );

    return (
        <div {...props}>
            <PanelContext.Provider
                value={{
                    clientHeight,
                    setClientHeight,
                    clientWidth,
                    setClientWidth,
                    onMouseHoldDown
                }}
            >
                {children}
            </PanelContext.Provider>
        </div>
    );
};

export default TwoHorizontalPanels;