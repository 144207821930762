import {userStore} from "../Login/userstore";
import jwtDecode from "jwt-decode";

const Home = () => {
    const user = userStore((state) => state.user);
    const bearer = userStore((state) => state.jwt);//This is the total string including Bearer
    const lengthToken = bearer.length;
    const token = bearer.substring(7, lengthToken - 7);//This is to split the Bearer from the token.
    const decoded = jwtDecode(token);

    const exp = new Date(decoded.exp * 1000).toLocaleTimeString();//This calculates the time, same trick is used in the next line
    const iat = new Date(decoded.iat * 1000).toLocaleTimeString();


    return (
        <div className="home">
            <h1>Welcome {user}</h1>
            <h3>You logged in at {iat}</h3>
            <h3>You have to log in again at {exp}</h3>
        </div>
    );
}
export default Home;