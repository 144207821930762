import CustomersChange from "./CustomersChange/CustomersChange";
import React from "react";

/*
* Last update date: 12-01-2023 Sander van Hijfte
* This one reacts on the SplitPaneTop
 */

const CustomersBottom = (props) => {
    return (
        <div {...props} className="split-pane-bottom">
<CustomersChange/>
        </div>
    )
};

export default CustomersBottom;