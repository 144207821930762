import {customersStore} from "../../../customers-store"
import {useEffect} from "react";

const CustomersList = () => {
    //=================== customersStore ===============
    const fetchCustomers = customersStore((state) => state.fetchCustomers);
    const customers = customersStore((state) => state.customers);
    const updated = customersStore((state) => state.customersAreUpdated);
    const fetchCustomer = customersStore((state) => state.fetchCustomer);
    const loadingCustomers = customersStore((state)=>state.loadingCustomers)
    //=================== customersStore ===============

    useEffect(() => {
        fetchCustomers();
    }, [updated])

    const selectCustomer = (event)=>{
        console.log("Selected Customer: " + event.target.id);
        fetchCustomer(event.target.id).then();
    }

    const customerList = customers.map(customer =>
        <div key={customer.customerId} className="customers-row u-margin-bottom-petit" onClick={selectCustomer}>
            <div className="customers-field" id={customer.customerId}>
                {customer.customerURL}
            </div>
            <div className="customers-field" id={customer.customerId} >
                {customer.customerName}
            </div>
            <div className="customers-field" id={customer.customerId} >
                {customer.customerCOCNumber}
            </div>
            <div className="customers-field" id={customer.customerId} >
                {customer.customerLocation}
            </div>

        </div>
    );

    if (loadingCustomers) return (<div>Loading</div>)

    if(!loadingCustomers)return (
           <div >
            {customerList}
        </div>
    )
}
export default CustomersList;