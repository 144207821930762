import {vacanciesStore} from "../../../vacancies-store";
import {userStore} from "../../../../Login/userstore";//We need the jwt and that sits in the userStore
import {useEffect, useState} from "react";
import {useImmer} from "use-immer";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {customersStore} from "../../../../Customers/customers-store";


/*
* Last update date: 21-06-2023 Sander van Hijfte
 */
const initialUpdate = {
    jwt: '',
    vacancy: {
        vacancyId: '',
        name: '',
        description: '',
        education: '',
        end_date: '',
        start_date: '',
        function_name: '',
        hours: '',
        city: '',
        option_to_extend: '',
        published: '',
        requirements: '',
        status: '',
        identifier: null,
        customer: '',
        customer_id: '',
        customer_vacancy_id: '',
        final_date_submission: ''
    }
}

const VacancyChange = (props) => {
    //================= VacanciesStore=====================
    const currentVacancy = vacanciesStore((state) => state.vacancy);
    const loadingVacancy = vacanciesStore((state) => state.loadingVacancy);
    const updateVacancy = vacanciesStore((state) => state.updateVacancy);
    const jwt = userStore((state) => state.jwt);
    const setUpdated = vacanciesStore((state) => state.setUpdated);//To trigger reloading VacanciesList
    const updated = vacanciesStore((state) => state.listIsUpdated);//And I have to toggle updated to trigger
    const initializeUpdate = vacanciesStore((state) => state.initializeUpdate);
    const deleteVacancy = vacanciesStore((state) => state.deleteVacancy);
    //================= VacanciesStore=====================

    //================= CustomersStore=====================
    const fetchCustomers = customersStore((state) => state.fetchCustomers);
    const customers = customersStore((state) => state.customers);
    //================= CustomersStore=====================


    const [update, setUpdate] = useImmer(initialUpdate);//update holds the values from the form

    useEffect(() => {
        // This loads the vacancy from the store into the one that is local for the update

        setUpdate({
                jwt: jwt,//from the userStore
                vacancy: {
                    vacancyId: currentVacancy.vacancyId,
                    name: currentVacancy.name,
                    description: currentVacancy.description,
                    education: currentVacancy.education,
                    end_date: currentVacancy.end_date,
                    start_date: currentVacancy.start_date,
                    function_name: currentVacancy.function_name,
                    hours: currentVacancy.hours,
                    city: currentVacancy.city,
                    option_to_extend: currentVacancy.option_to_extend,
                    published: currentVacancy.published,
                    requirements: currentVacancy.requirements,
                    status: currentVacancy.status,
                    identifier: currentVacancy.identifier,
                    customer: currentVacancy.customer,
                    customer_id: currentVacancy.customer_id,
                    customer_vacancy_id: currentVacancy.customer_vacancy_id,
                    final_date_submission: currentVacancy.final_date_submission
                }
            }
        );
        setCustomer(currentVacancy.customer);
        fetchCustomers().then();
    }, [currentVacancy]);

    //================= Begin Customer Selection=====================

    const setCustomer = ( valueToSelect) => {//This is the initial value that is displayed
        let element = document.getElementById("customer");
        element.value = valueToSelect;
    }
    //================= End Customer Selection=====================


    const customerList = customers.map(customer => //The blue customerId is a custom attribute I created and it holds the customerId from the customers in the database
            <option key={customer.customerId} customerId={customer.customerId} value={customer.customerName}>{customer.customerName}</option>
    );


    //================= Begin Change handlers=====================

    const customerChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.customer = event.target.value;//The name of the customer is the value
            let selectedIndex = event.target.options.selectedIndex;//This is the index of the list
            draft.vacancy.customer_id = event.target.options[selectedIndex].getAttribute('customerId');//Here we get the customerId from the selected item in the list.
            // console.log(selectedIndex);
            // console.log(event.target.options[selectedIndex].getAttribute('customerId'));
        });
    }

    const customer_vacancy_idChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.customer_vacancy_id = event.target.value;
        });
    }

    const nameChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.name = event.target.value;
        });
    }

    const function_nameChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.function_name = event.target.value;
        });
    }

    const educationChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.education = event.target.value;
        });
    }

    const hoursChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.hours = event.target.value;
        });
    }

    const cityChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.city = event.target.value;
        });
    }

    const option_to_extendChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.option_to_extend = event.target.value;
        });
    }

    const statusChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.status = event.target.value;
        });
    }

    const publishedChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.published = event.target.value;
        });
    }

    const start_dateChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.start_date = event.target.value;
        });
    }

    const end_dateChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.end_date = event.target.value;
        });
    }

    const final_date_submissionChangeHandler = (event) => {
        setUpdate(draft => {
            draft.vacancy.final_date_submission = event.target.value;
        });
    }

    //================= End Change handlers=====================

    //================= Begin Submission handling=====================
    const afterSubmit = () => {
        setUpdated(!updated);//the list of vacancies
        initializeUpdate();
    }
    const updateHandler = (event) => {
        event.preventDefault();
        console.log(update);
        updateVacancy(update).then(afterSubmit);//This call returns a promise, so we can use the .then operator
    }
    const cancelHandler = (event) => {
        event.preventDefault();
        initializeUpdate();
    }
    const deleteHandler = (event) => {
        event.preventDefault();
        const deleteInput = {
            jwt:jwt,
            vacancyId: update.vacancy.vacancyId
        }
        deleteVacancy(deleteInput).then(afterSubmit);
    }
//================= End Submission handling=====================
    return (
        // ============================Form=======================//
        <form className="vacancy-change">
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">
                        Customer
                    </div>
                </div>
                <div className="add-vacancy-customer-box">
                    <select onChange={customerChangeHandler} id="customer" className="add-vacancy-customer">
                        {customerList}
                    </select>
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Customer VacancyId</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="text"
                           id='customer_vacancy_id'
                           value={update.vacancy.customer_vacancy_id}
                           onChange={customer_vacancy_idChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Vacancy Name</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="text"
                           id='name'
                           value={update.vacancy.name}
                           onChange={nameChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Education</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="text"
                           id='education'
                           value={update.vacancy.education}
                           onChange={educationChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Hours</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="text"
                           id='hours'
                           value={update.vacancy.hours}
                           onChange={hoursChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">City</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="text"
                           id='city'
                           value={update.vacancy.city}
                           onChange={cityChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Option to Extend</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="text"
                           id='option_to_extend'
                           value={update.vacancy.option_to_extend}
                           onChange={option_to_extendChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Status</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="text"
                           id='status'
                           value={update.vacancy.status}
                           onChange={statusChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Published</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="date"
                           id='published'
                           value={update.vacancy.published}
                           onChange={publishedChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Start Date</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="date"
                           id='start_date'
                           value={update.vacancy.start_date}
                           onChange={start_dateChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">End Date</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="date"
                           id='end_date'
                           value={update.vacancy.end_date}
                           onChange={end_dateChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">Final Date Submission</div>
                </div>
                <div className="column">
                    <input className="vacancy-change__input"
                           type="date"
                           id='final_date_submission'
                           value={update.vacancy.final_date_submission}
                           onChange={final_date_submissionChangeHandler}
                    />
                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">
                        Description
                    </div>
                </div>
                <div className="column">
                    <CKEditor
                        editor={ClassicEditor}
                        data={update.vacancy.description}
                        onChange={(event,editor)=>{
                            setUpdate(draft => {
                                draft.vacancy.description = editor.getData();
                            });
                        }}
                        id='description'
                    />

                </div>
            </div>
            <div className="row-2-columns u-margin-bottom-petit">
                <div className="column">
                    <div className="label">
                        Requirements
                    </div>
                </div>
                <div className="column">
                    <CKEditor
                        editor={ClassicEditor}
                        data={update.vacancy.requirements}
                        onChange={(event,editor)=>{
                            setUpdate(draft => {
                                draft.vacancy.requirements = editor.getData();
                            });
                        }}
                        id='requirements'
                    />

                </div>
            </div>

            {/*===================Form===========================*/}

            {/*===================Buttons===========================*/}
            <div className="vacancy-change__row-2-columns vacancy-change__row-2-columns__three-buttons u-margin-bottom-petit">
                <div className="column">
                    <button className="change_btn" onClick={updateHandler}>Update</button>
                </div>
                <div className="column">
                    <button className="change_btn" onClick={deleteHandler}>Delete</button>
                </div>
                <div className="column">
                    <button className="change_btn" onClick={cancelHandler}>Cancel</button>
                </div>
            </div>
            {/*===================Buttons===========================*/}
        </form>
    );
}
export default VacancyChange;